import React from 'react'
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <p className={"opacity-50"}>
                Made by Alexander Koch
                <div className={"d-flex flex-column gap-2 align-items-center text-center w-100 hover-highlighted"}>
                    <Link to={"/impressum"}>Impressum</Link>
                </div>
            </p>
        </footer>
    )
}
