import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import "./scss/main.scss"

import Home from "./pages/Home/Home";
import Header from "./layouts/Header/Header";
import Footer from "./layouts/Footer/Footer";
import SiteNotFound from "./pages/SiteNotFound/SiteNotFound";
import Impressum from "./pages/Impressum/Impressum";

function App() {
    return (
        <Router>
            <Header/>
            <div id={"rootApp"}>
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="/impressum" element={<Impressum />}/>
                    <Route path="*" element={<SiteNotFound />}/>
                </Routes>
            </div>
            <Footer/>
        </Router>
    );
}

export default App;
