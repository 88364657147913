import {Link} from "react-router-dom";

const Impressum = () => {

    return (
        <div id={"Impressum"}>
            <div className={"container"}>
                <h1>Impressum</h1>
                <h3>Angaben gemäß § 5 TMG:</h3>
                <div>
                    Alexander Koch<br/>
                    48691 Vreden<br/>
                    Postfach: 1140<br/>
                    <br/>
                    Kontakt:<br/>
                    <br/>
                    E-Mail: alexander.koch@uxnetwork.eu<br/>
                    <br/>
                    Umsatzsteuer-Identifikationsnummer: DE364969858<br/>
                </div>
            </div>
        </div>
    );
};

export default Impressum;
