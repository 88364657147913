import React, { useEffect } from 'react';

export default function WaterEffect() {
    const maxDroplets = 20; // Set a limit for the number of droplets
    let droplets = [];

    useEffect(() => {
        const createParticle = (x, y, velocityX, velocityY) => {
            const particle = document.createElement('div');
            particle.className = 'water-particle';

            // Adjust the Y position based on the scroll position
            const adjustedX = x;
            const adjustedY = y + window.scrollY;

            particle.style.left = `${adjustedX}px`;
            particle.style.top = `${adjustedY}px`;

            const size = Math.random() * 15 + 5; // Random size between 5 and 20 pixels
            particle.style.width = `${size}px`;
            particle.style.height = `${size}px`;

            document.body.appendChild(particle);
            droplets.push(particle); // Add the new particle to the array

            // Limit the number of active droplets
            if (droplets.length > maxDroplets) {
                const oldestParticle = droplets.shift(); // Remove the oldest particle
                oldestParticle.remove(); // Remove it from the DOM
            }

            // Adjust the movement based on mouse speed and direction
            const speedFactor = Math.sqrt(velocityX * velocityX + velocityY * velocityY) / 10;
            const moveX = velocityX * speedFactor + (Math.random() - 0.5) * 50;
            const moveY = velocityY * speedFactor + (Math.random() - 0.5) * 50;

            const animationDuration = Math.random() * 1500 + 1000; // Random duration between 1s and 2.5s

            particle.animate(
                [
                    { transform: `translate(0, 0) scale(1)`, opacity: 1 },
                    { transform: `translate(${moveX}px, ${moveY}px) scale(0.8)`, opacity: 0 }
                ],
                {
                    duration: animationDuration,
                    easing: 'ease-out',
                }
            );

            // Remove the particle after its animation ends
            setTimeout(() => {
                particle.remove();
                droplets = droplets.filter(d => d !== particle); // Clean up the reference
            }, animationDuration);
        };

        let lastX = 0;
        let lastY = 0;
        let lastTime = Date.now();

        const handleMouseMove = (event) => {
            const { clientX, clientY } = event;
            const currentTime = Date.now();
            const deltaX = clientX - lastX;
            const deltaY = clientY - lastY;
            const timeDelta = currentTime - lastTime;

            const velocityX = deltaX / timeDelta;
            const velocityY = deltaY / timeDelta;

            // Only create particles if the mouse has moved a significant distance
            if (Math.abs(deltaX) > 10 || Math.abs(deltaY) > 10) {
                createParticle(clientX, clientY, velocityX, velocityY);
                lastX = clientX;
                lastY = clientY;
                lastTime = currentTime;
            }
        };

        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            droplets.forEach(particle => particle.remove()); // Clean up any remaining particles
        };
    }, []);

    return null; // This component does not render anything itself
}
