import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './assets/App';
import ReactGA from 'react-ga';
import './assets/utils/i18n';

// Initialize Google Analytics
ReactGA.initialize('G-1DF9VN168S');

// Report page view
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);