import React from 'react';
import Contact from "../../components/Contact";
import { useTranslation } from 'react-i18next';
import WaterEffect from "../../components/WaterEffect";

export default function Home() {
    const { t } = useTranslation();

    return (
        <div id={"Home"}>
            <WaterEffect />
            <div id={"about"} className={"namePresent"}>
                <h1>ALEXANDER <br /> KOCH</h1>
                <p dangerouslySetInnerHTML={{__html: t('home.about.description')}}></p>
                <a href="#contact">
                    <button className={"btn btn-primary"}>
                        {t('home.about.button')}
                    </button>
                </a>
            </div>
            <div id={"projects"} className={"projectsPresent"}>
                <h2>{t('home.projects.title')}</h2>
                <div className={"cards"}>
                    <a href={"https://www.quantumrealms.de/"} target={"_blank"} className="card">
                        <img className="card-img-top" src="https://www.quantumrealms.de/logo512.png" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">{t('home.projects.quantumRealms.title')}</h5>
                            <p className="card-text">{t('home.projects.quantumRealms.description')}</p>
                        </div>
                    </a>
                    <a href={"https://www.uxnetwork.eu/"} target={"_blank"} className="card">
                        <img className="card-img-top" src="https://www.uxnetwork.eu/logo512.png" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">{t('home.projects.uxNetwork.title')}</h5>
                            <p className="card-text">{t('home.projects.uxNetwork.description')}</p>
                        </div>
                    </a>
                    <a href={"https://www.kdc-catering.de/"} target={"_blank"} className="card">
                        <img className="card-img-top" src="https://www.kdc-catering.de/wp-content/uploads/2024/08/KDC-Logo-RGB-trans.png" alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title">{t('home.projects.catering.title')}</h5>
                            <p className="card-text">{t('home.projects.catering.description')}</p>
                        </div>
                    </a>
                </div>
            </div>
            <div id={"skills"} className={"skillsPresent"}>
                <h2 className="text-center">{t('home.skills.title')}</h2>
                <div className="row text-center cards">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.shopware.title')}</h5>
                            <p className="card-text">{t('home.skills.shopware.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.wordpress.title')}</h5>
                            <p className="card-text">{t('home.skills.wordpress.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.react.title')}</h5>
                            <p className="card-text">{t('home.skills.react.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.backend.title')}</h5>
                            <p className="card-text">{t('home.skills.backend.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.docker.title')}</h5>
                            <p className="card-text">{t('home.skills.docker.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.devops.title')}</h5>
                            <p className="card-text">{t('home.skills.devops.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.database.title')}</h5>
                            <p className="card-text">{t('home.skills.database.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.projectManagement.title')}</h5>
                            <p className="card-text">{t('home.skills.projectManagement.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.versionControl.title')}</h5>
                            <p className="card-text">{t('home.skills.versionControl.description')}</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">{t('home.skills.systemDevelopment.title')}</h5>
                            <p className="card-text">{t('home.skills.systemDevelopment.description')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Contact/>
        </div>
    )
}
