import React, {useEffect, useState} from 'react';
import {generateUUID} from "../../utils/generateUUID";
import ReactGA from "react-ga";
import {useTranslation} from 'react-i18next';
import i18n from "../../utils/i18n";
import {Link} from "react-router-dom";

export default function Header() {
    const {t} = useTranslation();
    const [translateState, setTranslateState] = useState(localStorage.getItem("language"));

    const globalLinks = [{
        "link": "/#projects", "title": "header.projects"
    }, {
        "link": "/#skills", "title": "header.skills"
    }, {
        "link": "/#contact", "title": "header.contact"
    }];

    const [opacity, setOpacity] = useState(1);

    useEffect(() => {
        const handleScroll = () => setOpacity(window.scrollY > 0 ? 0 : 1);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleLanguageChange = (lng) => {
        localStorage.setItem("language", lng);
        setTranslateState(lng);
        i18n.changeLanguage(lng);  // Change the language
    };

    return (<header style={{opacity, transition: 'opacity 0.3s ease'}}>
        <div className={"d-flex flex-row align-items-center gap-2"}>
            <Link to="/" className={"d-flex flex-row align-items-center gap-2 logoContainer"}>
                <img src="/logo512.png" alt="" className={"logo"}/>
                <h1 className={"text-white"}>
                    Alexander Koch
                </h1>
            </Link>
            {window.innerWidth > 500 && <div className={"languageButtons d-flex flex-row align-items-center gap-2"}>
                <button className={"hover-highlighted btn"} onClick={() => handleLanguageChange('de')}><h4>GER</h4></button>
                <div className={"divider"}>-</div>
                <button className={"hover-highlighted btn"} onClick={() => handleLanguageChange('en')}><h4>ENG</h4></button>
            </div> || <div className={"languageButtons d-flex flex-row align-items-center"}>
                {(translateState === null || translateState === "de") &&
                    <button className={"btn"} onClick={() => handleLanguageChange('en')}><img
                        src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Flag_of_Germany.svg"
                        alt="englishFlag"/>
                    </button> || <button className={"btn"} onClick={() => handleLanguageChange('de')}><img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHdgvdizEVQApGOKP-_08VPRdcqXMHcFdJpw&s"
                        alt="germanFlag"/></button>}
            </div>}

        </div>
        <div>
            {globalLinks.map((social) => (
                <a href={social.link} key={generateUUID()} className={"socialLink hover-highlighted"} title={social.title}
                   onClick={() => {
                       ReactGA.event({
                           category: 'header', action: 'Clicked the button', label: social.title
                       });
                   }}>
                    <button className="join">{t(social.title)}</button>
                </a>))}
        </div>
    </header>)
}
